import React, { useState, useEffect } from "react";
import { firebase, firestore } from "../../firebase";
import "./index.scss";
import {
  Typography,
  Grid,
  Container,
  Button,
  Paper,
  IconButton,
  Hidden
} from "@material-ui/core";
import WelcomeModal from "../../components/WelcomeModal";
import Profile from "./Profile";
import Questions from "./Questions";
import Progress from "./Progress";
import Exams from "./Exams";
import Revision from "./Revision";
import Bookmarks from "./Bookmarks";
import Tour from "reactour";
import Footer from "../../components/Footer";
import ReferralImg from "../../images/referral.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  LineShareButton,
  EmailShareButton
} from "react-share";

import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import RedditIcon from "@material-ui/icons/Reddit";
import TwitterIcon from "@material-ui/icons/Twitter";

const dateString = timeStamp => {
  if (timeStamp) {
    const date = new Date(timeStamp.seconds * 1000);
    return date.toDateString();
  }
};

const Dashboard = props => {
  const [user, setUser] = useState({});
  const [topics, setTopics] = useState({});
  const [submissions, setSubmissions] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [totalQuestionCount, setTotalQuestionCount] = useState([]);
  const [referal, setReferal] = useState({});
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [dontShowFlag, setDontShowFlag] = useState(false);

  const fetchTopics = () => {
    firestore
      .collection("topics")
      .where("exam", "==", "plab")
      .get()
      .then(snapShot => {
        const topics = {};
        let questionCount = 0;
        snapShot.forEach(topic => {
          const data = topic.data();
          questionCount += Object.keys(data.questions || {}).length;
          topics[topic.id] = { ...data, id: topic.id };
        });
        setTotalQuestionCount(questionCount);
        fetchSubmissions(topics);
      })
      .catch(e => {
        console.log(e);
        window.location.href = "/auth";
      });
  };

  const fetSubscriptions = uid => {
    firestore
      .collection("subscriptions")
      .doc(uid)
      .get()
      .then(snap => {
        const doc = snap.data();
        setSubscription(doc);
      });
  };

  const fetchSubmissions = topics => {
    firebase.auth().onAuthStateChanged(function(user) {
      firestore
        .collection("submissions")
        .doc(user.uid)
        .get()
        .then(snapShot => {
          const finalTopics = {};
          const submissions = snapShot.data() || {};
          Object.keys(topics || {}).forEach(topicId => {
            finalTopics[topicId] = {
              submissionCount:
                Object.keys(submissions[topicId] || {}).length - 1,
              ...topics[topicId],
              lastAttempted: dateString(
                (submissions[topicId] || {}).lastAttempted
              )
            };
          });
          setSubmissions(submissions);
          setTopics(finalTopics);
        });
    });
  };

  const fetchReferals = async id => {
    const refSnap = await firestore
      .collection("referrals")
      .doc(id)
      .get();
    setReferal(refSnap.data());
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userSnap = await firestore
          .collection("users")
          .doc(user.uid)
          .get();

        setUser({
          ...userSnap.data(),
          id: userSnap.id
        });

        fetchTopics();
        fetSubscriptions(user.uid);
        fetchReferals(user.uid);
      } else {
        // User not logged in or has just logged out.
      }
    });
  }, []);

  const steps = [
    {
      selector: ".profile",
      content: ({ goTo, inDOM }) => (
        <div className="tour-text">
          <Typography variant="subtitle2" gutterBottom>
            Profile section
          </Typography>
          <Typography>
            You can check your subscription status and your referral earnings.
            🎉
          </Typography>
        </div>
      )
    },

    {
      selector: ".progress",
      content: ({ goTo, inDOM }) => (
        <div className="tour-text">
          <Typography variant="subtitle2" gutterBottom>
            Progress and performance
          </Typography>
          <Typography>
            Keep track of your progress and performance here. You can see the
            percentage complete and percentage right/wrong per topic.
          </Typography>
        </div>
      )
    },
    {
      selector: ".revision",
      content: ({ goTo, inDOM }) => (
        <div className="tour-text">
          <Typography variant="subtitle2" gutterBottom>
            Smart Revision 🧠
          </Typography>
          <Typography>
            Smart revision automatically curates questions that you my need to
            brush up. It spaces out questions with specified intervals for
            optimal memory retention. You can also add question manually!
          </Typography>
        </div>
      )
    },
    {
      selector: ".bookmarks",
      content: ({ goTo, inDOM }) => (
        <div className="tour-text">
          <Typography variant="subtitle2" gutterBottom>
            Bookmarks
          </Typography>
          <Typography>Want to get back to a question, bookmark it!</Typography>
        </div>
      )
    },
    {
      selector: ".dashboard-questionlist",
      content: ({ goTo, inDOM }) => (
        <div className="tour-text">
          <Typography variant="subtitle2" gutterBottom>
            Question List
          </Typography>
          <Typography>
            Questions grouped by topic. Click on a topic to browse questions,
            read notes and more.
          </Typography>
        </div>
      )
    },
    {
      selector: ".dashboard-exams",
      content: ({ goTo, inDOM }) => (
        <div className="tour-text">
          <Typography variant="subtitle2" gutterBottom>
            Timed Exams
          </Typography>
          <Typography>
            This is a great way to train your self for exam day. You can select
            the number of questions and the time to complete.
          </Typography>
          <br />
          <Button
            size="small"
            color="primary"
            onClick={onNo}
            variant="contained"
          >
            End The Tou
          </Button>
        </div>
      )
    }
  ];

  const onNo = () => {
    setIsTourOpen(false);
    window.localStorage.setItem("touredDashboard", true);
  };

  const setDontShow = () => {
    setDontShowFlag(true);
    window.localStorage.setItem("dontShowFlag", true);
  };

  const shareLink = `${window.location.origin}?ref=${user.refToken}`;
  const shareText = `I'm using Medrevisions.com to prepare for my PLAB exams. Use this referral link to get a 10% discount. Share the good karma!`;

  return (
    <>
      <Tour steps={steps} isOpen={isTourOpen} onRequestClose={onNo} />
      <WelcomeModal
        pageName={"Dashboard Page"}
        onNo={onNo}
        onYes={() => {
          setIsTourOpen(true);
        }}
      />
      <div className="dashboard">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Profile
                user={user}
                subscription={subscription}
                referal={referal}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Progress submissions={submissions} topics={topics} />
            </Grid>
          </Grid>

          {!dontShowFlag && !window.localStorage.getItem("dontShowFlag") && (
            <Grid container spacing={2} className="dashbaord-container">
              <Grid item xs={12}>
                <Paper className="referal-section">
                  <Hidden xsDown>
                    <img className="referral-img" src={ReferralImg} />
                  </Hidden>
                  <div>
                    <Typography
                      component="h4"
                      className="section-title"
                      align="center"
                      gutterBottom
                    >
                      Referral Program
                    </Typography>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                      Use your unique referral link to earn{" "}
                      <strong>
                        US$5 every time someone signs up using your link
                      </strong>
                      . They will receive a 10% discount! <br />
                      And it's just good karma!
                      <br />
                      <div className="link"> Referral Link: {shareLink}</div>
                    </Typography>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                      Click on a social media below for quick share
                    </Typography>
                    <div className="social-links">
                      <FacebookShareButton
                        url={shareLink}
                        className="button-link"
                        quote={shareText}
                      >
                        <IconButton>
                          <FacebookIcon />
                        </IconButton>
                      </FacebookShareButton>

                      <LinkedinShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <LinkedInIcon />
                        </IconButton>
                      </LinkedinShareButton>

                      <TwitterShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                        hashtags={["medrevisions"]}
                      >
                        <IconButton>
                          <TwitterIcon />
                        </IconButton>
                      </TwitterShareButton>

                      <TelegramShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <TelegramIcon />
                        </IconButton>
                      </TelegramShareButton>

                      <WhatsappShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <WhatsAppIcon />
                        </IconButton>
                      </WhatsappShareButton>

                      <RedditShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <RedditIcon />
                        </IconButton>
                      </RedditShareButton>

                      <Button>
                        <LineShareButton
                          url={shareLink}
                          title={shareText}
                          className="button-link"
                        >
                          Line
                        </LineShareButton>
                      </Button>

                      <EmailShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <EmailIcon />
                        </IconButton>
                      </EmailShareButton>
                    </div>
                    <br />
                    <Button
                      onClick={() => setDontShow()}
                      className="dont-show-button"
                      size={"small"}
                    >
                      Dont show this message
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Revision user={user} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Bookmarks user={user} />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Questions
                submissions={submissions}
                totalQuestionCount={totalQuestionCount}
                topics={topics}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Exams user={user} availableQuestions={totalQuestionCount} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
