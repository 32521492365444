import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText
} from "@material-ui/core";
import "./index.scss";
import ProductTour from "../../images/product_tour.svg";

const ReportModal = ({ pageName, onYes, onNo }) => {
  const [open, setOpen] = useState(
    !window.localStorage.getItem("touredDashboard")
  );
  const handleClose = () => {
    setOpen(false);
    onNo();
  };

  const handleYes = () => {
    setOpen(false);
    onYes();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{pageName} Tour</DialogTitle>
      <DialogContent className="tour-container">
        <img src={ProductTour} className="tour-img" alt="Take a product tour" />
        <DialogContentText>
          Welcome to the {pageName}! Take the quick tour to get to know all the
          features here!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Nah, I'm good</Button>
        <Button onClick={handleYes} color="primary">
          Start the Tour
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportModal;
