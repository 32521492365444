import React from "react";
import { Paper, Container, Typography, Grid, Button } from "@material-ui/core";
import Footer from "../../components/Footer";
import TextField from "@material-ui/core/TextField";
import { firestore, firebase } from "../../firebase";
import ContactImage from "../../images/contact_us.svg";
import "./index.scss";

const Questions = () => {
  const [messageSending, setMessageSending] = React.useState(false);
  const [messageSent, setMessageSent] = React.useState(false);
  const [values, setValues] = React.useState({
    name: null,
    email: null,
    message: null
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const submit = e => {
    setMessageSending(true);
    e.preventDefault();
    firestore
      .collection("contactMessages")
      .add({
        ...values,
        date: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        setMessageSent(true);
      });
  };

  return (
    <>
      <Container>
        <Paper elevation={1} className="terms-privacy-wrapper">
          <Typography variant="h3" gutterBottom>
            Contact US
          </Typography>
          <br />
          <br />

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <img
                src={ContactImage}
                className="contact-img"
                alt="Contact us"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <>
                {messageSent && (
                  <Typography>Thank you! Message recieved.</Typography>
                )}
                <br />
                <form className="contact-form" onSubmit={submit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        required
                        type="text"
                        value={values.name}
                        onChange={handleChange("name")}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Email"
                        type="email"
                        value={values.email}
                        onChange={handleChange("email")}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    required
                    multiline
                    fullWidth
                    label="Message"
                    type="text"
                    value={values.message}
                    onChange={handleChange("message")}
                    margin="normal"
                  />
                  <Button
                    disabled={messageSent || messageSending}
                    className="contact-submit"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              </>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Questions;
