import "./index.scss";

import {
  Avatar,
  Breadcrumbs,
  Button,
  CardActions,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { firebase, firestore } from "../../firebase";

import AddIcon from "@material-ui/icons/Add";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import ExamModal from "./ExamModal";
import Footer from "../../components/Footer";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Reference from "./Reference";
import ReportIcon from "@material-ui/icons/Report";
import ReportModal from "./ReportModal";
import StarsIcon from "@material-ui/icons/Stars";
import draftToHtml from "draftjs-to-html";
import { getRevisionQuestions } from "../../util";
// import { Editor as TinyEditor } from "@tinymce/tinymce-react";
import TinyView from "../../components/Editor/tiny";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
    marginBottom: "1.5rem",
  },
  correct: {
    backgroundColor: "#c8e6c9",
    padding: "0 10px",
    borderRadius: "3px",
  },
  wrong: {
    backgroundColor: "#ffcdd2",
    padding: "0 10px",
    borderRadius: "3px",
  },
  info: {
    backgroundColor: "#b3e5fc63",
    padding: "0 10px",
    borderRadius: "3px",
  },
}));

const Questions = (props) => {
  const { match } = props;
  const [qIds, setQIds] = useState([]);
  const [allQuestions, setAllQuestions] = useState({});
  const [fullQuestions, setFullQuestions] = useState({});
  const [pastPaperQuestions, setPastPaperQuestions] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [currentQ, setCurrentQ] = useState(null);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const [submissionPercent, setSubmissionPercent] = useState({});
  const [exam, setExam] = useState(null);
  const [submissions, setSubmissions] = useState({});
  const [showPercents, setShowPercents] = useState(true);
  const [loading, setLoading] = useState(true);
  const [bookmarking, setBookmarking] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [expiredExam, setExpiredExam] = useState();
  const [examSeconds, setExamSeconds] = useState(0);
  const [bookmarks, setBookmarks] = useState({});
  const [examMin, setExamMin] = useState(0);
  const [reportmodal, setReportmodal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [smartRevisionSet, setSmartRevisionSet] = React.useState({});
  const [pastPaperQsOnly, setPastPaperQsOnly] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    type: "",
    msg: "",
  });

  let keyMap = {};

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  window.document.addEventListener("keydown", function (e) {
    if (
      (e.keyCode === 44 ||
        e.keyCode === 51 ||
        e.keyCode === 52 ||
        e.keyCode === 83 ||
        e.keyCode === 115) &&
      keyMap[17]
    ) {
      alert(
        "Screen prints or any form of screen capture violates the terms and conditions of Medrevisions.com"
      );
      window.location.reload();
      return false;
    } else if (e.keyCode === 17) {
      keyMap[17] = true;
    } else {
      let keyMap = {};
    }
  });

  var examTimer = null;
  const reportModelClosed = (submitted) => {
    if (submitted) {
      setSnackBar({
        open: true,
        msg: "Feedback Submitted!",
      });
    }
    setReportmodal(false);
  };

  function handleRefPanelClick(event) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const fetchQuestionList = async () => {
    // try {
    if (match.params.subTopicID) {
      const pastQs = {};
      const allQs = {};
      firestore
        .collection("questions")
        .where("topicId", "==", match.params.topicOrExamId)
        .where("subTopicId", "==", match.params.subTopicID)
        .get()
        .then(async (snaps) => {
          const questions = snaps.docs.map((d) => d.id);
          snaps.forEach((snap) => {
            const d = snap.data();
            if (d.pastPaperQuestion) {
              pastQs[snap.id] = true;
            }
            allQs[snap.id] = true;
          });
          setQIds(questions);
          setAllQuestions(allQs || {});
          setPastPaperQuestions(pastQs || {});
          await fetchQuestion(0, questions);
        });
    } else if (match.path.match(/demo/)) {
      firestore
        .collection("demoQuestions")
        .doc(match.params.type)
        .get()
        .then(async (snapShot) => {
          const data = snapShot.data();
          setQIds(data.questions);
          await fetchQuestion(0, data.questions);
        });
    } else if (match.path.match(/exam/)) {
      const snapShot = await firestore
        .collection("timedExams")
        .doc(match.params.topicOrExamId)
        .get();
      const data = snapShot.data();
      setQIds(data.questions);
      await fetchQuestion(0, data.questions);
    } else if (match.path.match(/revision/)) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          firestore
            .collection("revision")
            .doc(user.uid)
            .get()
            .then(async (revisionSnap) => {
              const revisionData = revisionSnap.data();
              const revisionObj = getRevisionQuestions(revisionData.questions);
              const allQuestions = [
                ...revisionObj.one,
                ...revisionObj.seven,
                ...revisionObj.sixTeen,
                ...revisionObj.thirtyFive,
              ];
              if (allQuestions.length === 0) {
                window.location.href = "/dashboard";
              } else {
                setQIds(allQuestions);
                await fetchQuestion(0, allQuestions);
              }
            });
        }
      });
    } else if (match.path.match(/bookmarks/)) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          firestore
            .collection("bookmarks")
            .doc(user.uid)
            .get()
            .then(async (bookmarkSnap) => {
              const bookmarks = Object.keys(bookmarkSnap.data() || {});
              if (bookmarks.length === 0) {
                window.location.href = "/dashboard";
              } else {
                setQIds(bookmarks);
                await fetchQuestion(0, bookmarks);
              }
            });
        }
      });
    } else {
      firestore
        .collection("topics")
        .doc(match.params.topicOrExamId)
        .get()
        .then(async (snapShot) => {
          const data = snapShot.data();
          setQIds(Object.keys(data.questions || {}));
          setPastPaperQuestions(data.pastQuestions || {});
          setAllQuestions(data.questions || {});
          await fetchQuestion(0, Object.keys(data.questions || {}));
        });
    }
    // } catch (error) {
    //   window.location.href = "/auth";
    // }
  };

  const togglePastpaperQs = async (checked) => {
    setPastPaperQsOnly(checked);
    setLoading(true);
    if (!checked) {
      setQIds(Object.keys(allQuestions || {}));
      await fetchQuestion(0, Object.keys(allQuestions || {}));
    } else {
      setQIds(Object.keys(pastPaperQuestions || {}));
      await fetchQuestion(0, Object.keys(pastPaperQuestions || {}));
    }
    setLoading(false);
  };

  const fetchSubmissions = async (user) => {
    const submissionSnap = await firestore
      .collection("submissions")
      .doc(user.uid)
      .get();
    const data = submissionSnap.data();
    if (data) {
      setSubmissions(data[match.params.topicOrExamId] || {});
    } else {
      setSubmissions({});
    }
  };

  const fetchQuestion = (index, qIds) => {
    try {
      return firestore
        .collection("questions")
        .doc(qIds[index])
        .get()
        .then((snapShot) => {
          if (snapShot.exists) {
            const data = snapShot.data();
            data.id = snapShot.id;
            data.question = draftToHtml(data.question);
            data.description = draftToHtml(data.description).replace(
              /<br>/g,
              ""
            );

            const ql = { ...fullQuestions, [index]: data };
            setFullQuestions(ql);
            setCurrentQ(index);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    } catch (error) {
      window.location.href = "/auth";
    }
  };

  const bookmarkQuestion = () => {
    firebase.auth().onAuthStateChanged((user) => {
      setBookmarking(true);
      if (user) {
        const q = fullQuestions[currentQ];
        const data = {
          title: q.title,
          topic: q.topic,
          subTopic: q.subTopic,
        };
        firestore
          .collection("bookmarks")
          .doc(user.uid)
          .set({ [q.id]: data }, { merge: true })
          .then((submissionSnap) => {
            setBookmarking(false);
            setBookmarks({ ...bookmarks, [q.id]: true });
            setSnackBar({
              open: true,
              type: "success",
              msg: "Sucessfully Bookmarked",
            });
          });
      }
    });
  };

  const getBookmarks = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection("bookmarks")
          .doc(user.uid)
          .get()
          .then((bookmarksSnap) => {
            setBookmarks(bookmarksSnap.data());
          });
      }
    });
  };

  const getSmartRevisionSet = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection("revision")
          .doc(user.uid)
          .get()
          .then((revisionSnap) => {
            setSmartRevisionSet((revisionSnap.data() || {}).questions);
          });
      }
    });
  };

  const postSubmission = () => {
    const cQuestion = fullQuestions[currentQ];
    const isCorrect = cQuestion.correctAnswer === selectedAnswer;
    setAnswerPercent(cQuestion);

    const s = { ...submissions };
    s[cQuestion.id] = { correct: isCorrect, selectedAnswer };
    setSubmissions(s);

    if (match.path.match(/exam/) && currentQ === qIds.length - 1) {
      endExam();
    }
    if (match.path.match(/exam/)) {
      setSubmittingAnswer(false);
      return nextQuestion();
    }
    setSubmittingAnswer(false);
    setAnswerSubmitted(true);
  };

  const saveToSmartRevision = () => {
    firebase.auth().onAuthStateChanged(function (user) {
      const cQuestion = fullQuestions[currentQ];
      const revisionRef = firestore.collection("revision").doc(user.uid);
      revisionRef
        .set(
          {
            questions: {
              [cQuestion.id]: {
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                count: {},
              },
            },
          },
          { merge: true }
        )
        .then(() => {
          setSmartRevisionSet({ ...smartRevisionSet, [cQuestion.id]: true });
          setSnackBar({
            open: true,
            type: "success",
            msg: "Sucessfully added question to Smart Revision",
          });
        });
    });
  };

  const saveSubmission = () => {
    setSubmittingAnswer(true);
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        const batch = firestore.batch();
        const cQuestion = fullQuestions[currentQ];
        const isCorrect = cQuestion.correctAnswer === selectedAnswer;
        cQuestion.submissions = cQuestion.submissions || {};
        const revisionRef = firestore.collection("revision").doc(user.uid);
        if (match.path.match(/demo/)) {
          postSubmission();
          return;
        } else if (!match.path.match(/revision/)) {
          const submissionQuestionRef = firestore
            .collection("submissions")
            .doc(user.uid)
            .collection(match.params.topicOrExamId || cQuestion.topicId)
            .doc(cQuestion.id);
          const submissionTopicRef = firestore
            .collection("submissions")
            .doc(user.uid);

          batch.set(
            submissionQuestionRef,
            {
              correct: isCorrect,
              selectedAnswer,
            },
            { merge: true }
          );
          if (!isCorrect) {
            batch.set(
              revisionRef,
              {
                questions: {
                  [cQuestion.id]: {
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    count: {},
                  },
                },
              },
              { merge: true }
            );
          }
          if (!match.path.match(/exam/)) {
            batch.set(
              submissionTopicRef,
              {
                [cQuestion.topicId]: {
                  lastAttempted: firebase.firestore.FieldValue.serverTimestamp(),
                },
              },
              { merge: true }
            );
          }
        } else {
          // Its in revision mode
          batch.set(
            revisionRef,
            {
              questions: {
                [cQuestion.id]: {
                  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                  count: { [Date.now()]: true },
                },
              },
            },
            { merge: true }
          );
        }

        batch.commit().then(() => {
          postSubmission();
        });
      } else {
        if (match.path.match(/demo/)) {
          postSubmission();
        } else {
          window.location.href = "/";
        }
      }
    });
  };

  const checkAndSetAnswered = () => {
    const question = fullQuestions[currentQ];
    if (question && submissions[question.id]) {
      setSelectedAnswer(submissions[question.id].selectedAnswer);
      setAnswerSubmitted(true);
      setAnswerPercent(question);
    } else if (question && match.path.match(/exam/) && expiredExam) {
      setSelectedAnswer(question.correctAnswer);
      setAnswerSubmitted(true);
      setAnswerPercent(question);
    } else {
      setSelectedAnswer(null);
      setAnswerSubmitted(false);
    }
  };

  const setAnswerPercent = (question) => {
    const percents = {};
    const totoalSubmissions = Object.values(question.submissions || {}).reduce(
      (p, c) => p + c,
      0
    );
    if (totoalSubmissions === 0) {
      setShowPercents(false);
    } else {
      Object.keys(question.answers || {}).forEach((aId) => {
        percents[aId] = Math.floor(
          ((question.submissions[aId] || 0) / totoalSubmissions) * 100
        );
      });
      setSubmissionPercent(percents);
    }
  };

  const preQuestion = () => {
    const q = currentQ === 0 ? qIds.length - 1 : currentQ - 1;
    setCurrentQuestion(q);
  };

  const nextQuestion = () => {
    const q = currentQ === qIds.length - 1 ? 0 : currentQ + 1;
    setCurrentQuestion(q);
  };

  const setCurrentQuestion = async (q) => {
    if (currentQ !== q) {
      if (!fullQuestions[q]) {
        await fetchQuestion(q, qIds);
      }
      setCurrentQ(q);
    }
    window.scrollTo(0, 0);
  };

  const submitAnswer = () => {
    if (selectedAnswer) {
      saveSubmission();
    } else {
      alert("Please select an answer");
    }
  };

  const updateExamTimes = (exam) => {
    let timeElapsed = 0;
    if (exam.ended) {
      setExpiredExam(true);
    } else if (exam.startedAt) {
      examTimer = setInterval(() => {
        timeElapsed = Date.now() / 1000 - exam.startedAt.seconds;
        const secondsRemaining = exam.time * 60 - timeElapsed;
        const min = Math.floor(secondsRemaining / 60);
        const sec = secondsRemaining - min * 60;
        setExpiredExam(secondsRemaining < 0);
        setExamSeconds(Math.floor(sec));
        setExamMin(min);
      }, 1000);
      if (timeElapsed < 0) clearInterval(examTimer);
    }
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar({ open: false });
  };

  const endExam = () => {
    firestore
      .collection("timedExams")
      .doc(match.params.topicOrExamId)
      .set({ ended: true }, { merge: true })
      .then(() => {
        window.location.reload();
      });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        firestore
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              var data = doc.data();
              setIsAdmin(data.admin);
            }
          })
          .catch((error) => {
            console.error("[signInSuccessCallBack]", error);
          });

        fetchSubmissions(user);
        fetchQuestionList();
        getBookmarks();
        getSmartRevisionSet();
        if (match.path.match(/exam/)) {
          firestore
            .collection("timedExams")
            .doc(match.params.topicOrExamId)
            .onSnapshot((examSnap) => {
              const exam = examSnap.data();
              setExam(exam);
              updateExamTimes(exam);
            });
        } else {
          setTimeout(() => {
            // For some reson the user object was not initialized
            // So we redirect the user to login
            const user = firebase.auth().currentUser;
            if (!user) {
              alert(
                "We could not authenticate your account, please refresh this page to try again."
              );
            }
          }, 5000);
        }
      } else {
        if (match.path.match(/demo/)) {
          fetchQuestionList();
        }
      }
    });
  }, []);

  useEffect(() => {
    checkAndSetAnswered();
  }, [currentQ]);

  const {
    id,
    topic,
    answers,

    question,
    answerDescriptions,
    correctAnswer,
    description,
    _description,
    revisionNotes,
    explanationNotes,
    helpfulLinks,
  } = fullQuestions[currentQ] || {};
  console.log("🚀 ~ file ~ explanationNotes", explanationNotes);

  const hideDesriptions = match.path.match(/exam/) && !expiredExam;

  const openRefPanel = Boolean(anchorEl);
  const refId = openRefPanel ? "simple-popper" : undefined;

  return (
    <>
      <Container className="questions-container">
        <Paper elevation={1} className="question-content header">
          <Breadcrumbs separator="›" aria-label="Breadcrumb">
            <Link color="inherit" href="/dashboard">
              Dashboard
            </Link>
            {hideDesriptions ? (
              <Typography color="textPrimary">Timed Exam</Typography>
            ) : (
              <Typography color="textPrimary">
                {topic} {match.path.match(/revision/) && " | Revision"}
              </Typography>
            )}
          </Breadcrumbs>
          <div className="spacer" />
          {exam && (
            <>
              <Button
                disabled={expiredExam}
                onClick={endExam}
                className="end-examButton"
                variant="outlined"
                color="primary"
              >
                End Exam
              </Button>
              <div className="timer">
                <Typography variant="overline">
                  Time remaining (MM:SS)
                </Typography>
                <Typography variant="h3">
                  {expiredExam ? "Ended" : `${examMin}:${examSeconds}`}
                </Typography>
              </div>
            </>
          )}
          {match.path.match(/demo/) && (
            <Button variant="contained" color="primary" href="/auth">
              Sign up!
            </Button>
          )}

          {Object.keys(pastPaperQuestions).length > 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={pastPaperQsOnly}
                  onChange={(e) => togglePastpaperQs(e.target.checked)}
                  color="primary"
                />
              }
              label="Attempt Recalls/High-yield questions only"
            />
          )}
        </Paper>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={9}>
            {!loading ? (
              <>
                <div className="question-header">
                  <IconButton
                    aria-label="Previous Question"
                    onClick={preQuestion}
                  >
                    <KeyboardArrowLeftIcon fontSize="large" />
                  </IconButton>
                  <div className="spacer" />
                  <Typography variant="h4" align="center" component="p">
                    {currentQ + 1}/{qIds.length}
                  </Typography>
                  <div className="spacer" />

                  <IconButton aria-label="Next Question" onClick={nextQuestion}>
                    <KeyboardArrowRightIcon fontSize="large" />
                  </IconButton>
                </div>

                <Paper className="question-content">
                  <div className="question-actions">
                    <Typography variant="subtitle2">
                      Question: {currentQ + 1}
                    </Typography>
                    <div className="spacer" />
                    <Button
                      size="small"
                      onClick={() => {
                        setReportmodal(true);
                      }}
                    >
                      <ReportIcon />
                      <Hidden smDown> Feedback </Hidden>
                    </Button>
                    {!match.path.match(/bookmarks/) && (
                      <Button
                        size="small"
                        aria-label="Bookmark"
                        onClick={bookmarkQuestion}
                        disabled={bookmarking || Boolean((bookmarks || {})[id])}
                        color="primary"
                      >
                        <BookmarkIcon />
                        <Hidden smDown> Bookmark </Hidden>
                      </Button>
                    )}

                    <Button
                      size="small"
                      aria-label="Bookmark"
                      onClick={saveToSmartRevision}
                      disabled={Boolean((smartRevisionSet || {})[id])}
                      color="primary"
                    >
                      <AddIcon />

                      <Hidden smDown> Smart Revision </Hidden>
                    </Button>
                  </div>
                  {question && isAdmin && (
                    <div>
                      <Typography variant="subtitle2">
                        Question From New Editor
                      </Typography>
                      <TinyView value={question} />
                    </div>
                  )}

                  <Typography variant="body1" component="span">
                    <span
                      className="question-text"
                      dangerouslySetInnerHTML={{
                        __html: question,
                      }}
                    />
                    {!question && (
                      <Typography variant="h5" color="textSecondary">
                        This question has been removed
                      </Typography>
                    )}
                  </Typography>

                  {question && (
                    <div className="answers">
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Answers</FormLabel>

                        <RadioGroup
                          aria-label="Answer"
                          name="answer"
                          value={selectedAnswer}
                          onChange={(_, v) => {
                            setSelectedAnswer(v);
                          }}
                        >
                          {Object.keys(answers || {}).map((a) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <FormControlLabel
                                disabled={
                                  match.path.match(/exam/)
                                    ? expiredExam
                                      ? true
                                      : false
                                    : answerSubmitted || expiredExam
                                }
                                key={a}
                                value={a}
                                control={<Radio />}
                                label={
                                  <div className="answer-label">
                                    <Typography
                                      variant="subtitle1"
                                      className="answer-text"
                                    >
                                      {answers[a]}
                                    </Typography>
                                    {answerSubmitted && !hideDesriptions && (
                                      <>
                                        {(selectedAnswer === a ||
                                          correctAnswer === a) && (
                                          <Chip
                                            size="small"
                                            avatar={
                                              <Avatar>
                                                {correctAnswer === a ? (
                                                  <CheckIcon />
                                                ) : (
                                                  <ClearIcon />
                                                )}
                                              </Avatar>
                                            }
                                            label={
                                              correctAnswer === a
                                                ? "Correct!"
                                                : "Wrong"
                                            }
                                            className={`${
                                              correctAnswer === a
                                                ? "correct"
                                                : "wrong"
                                            } chip`}
                                          />
                                        )}
                                        {showPercents && (
                                          <Chip
                                            className="chip"
                                            size="small"
                                            label={`${
                                              submissionPercent[a] || 0
                                            }%`}
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                }
                              />
                              {isAdmin &&
                                answerSubmitted &&
                                !hideDesriptions &&
                                answerDescriptions[a] && (
                                  <>
                                    <div
                                      className={
                                        selectedAnswer === a ||
                                        correctAnswer === a
                                          ? correctAnswer === a
                                            ? classes.correct
                                            : classes.wrong
                                          : classes.info
                                      }
                                    >
                                      {" "}
                                      <TinyView value={answerDescriptions[a]} />
                                      {/* <span
                                      dangerouslySetInnerHTML={{
                                        __html: answerDescriptions[a].replace(
                                          /\n/g,
                                          "<br/>"
                                        ),
                                      }}
                                    /> */}
                                    </div>
                                  </>
                                )}
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}

                  <CardActions>
                    <div className="spacer" />
                    {answerSubmitted ? (
                      <>
                        {/* {!match.path.match(/bookmarks/) && (
                          <Button
                            onClick={bookmarkQuestion}
                            disabled={
                              bookmarking || Boolean((bookmarks || {})[id])
                            }
                          >
                            <BookmarkIcon />
                            {(bookmarks || {})[id] ? "Bookmarked" : "Bookmark"}
                          </Button>
                        )} */}

                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={nextQuestion}
                        >
                          Next Question
                          <NavigateNextIcon />
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitAnswer}
                        disabled={submittingAnswer || expiredExam}
                      >
                        {submittingAnswer ? (
                          <CircularProgress size={30} />
                        ) : match.path.match(/exam/) &&
                          currentQ === qIds.length - 1 ? (
                          "Submit and end Exam"
                        ) : (
                          "Submit Answer"
                        )}
                      </Button>
                    )}
                  </CardActions>
                </Paper>

                {answerSubmitted && !hideDesriptions && (
                  <Paper className="question-content">
                    <Typography variant="h6" gutterBottom>
                      Answer Description
                    </Typography>
                    {Object.keys(answerDescriptions || {}).map(
                      (a) =>
                        answerDescriptions[a] && (
                          <div key={a}>
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              {answers[a]}
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                              {/* <span
                                dangerouslySetInnerHTML={{
                                  __html: answerDescriptions[a].replace(
                                    /\n/g,
                                    "<br/>"
                                  ),
                                }}
                              /> */}
                              <TinyView value={answerDescriptions[a]} />
                            </Typography>
                            <br />
                          </div>
                        )
                    )}
                    {Object.values(answerDescriptions || {}).reduce(
                      (a, i) => a && i.length === 0,
                      true
                    ) && (
                      <Typography color="textSecondary">
                        No Answer descriptions
                      </Typography>
                    )}
                  </Paper>
                )}

                {description &&
                  description.length > 0 &&
                  answerSubmitted &&
                  !hideDesriptions && (
                    <Paper className="question-content">
                      <Typography variant="h6">Study Notes</Typography>
                      <span
                        className="question-text"
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </Paper>
                  )}
                {/* {isAdmin &&
                  _description &&
                  _description.length > 0 &&
                  answerSubmitted &&
                  !hideDesriptions && (
                    <Paper className="question-content">
                      <Typography variant="h6">
                        Study Notes new from new editor
                      </Typography>
                      <span
                        className="question-text"
                        dangerouslySetInnerHTML={{
                          __html: _description,
                        }}
                      />
                    </Paper>
                  )} */}

                {/* {revisionNotes &&
                  revisionNotes.length > 0 &&
                  answerSubmitted &&
                  !hideDesriptions && (
                    <Paper className="question-content">
                      <Typography variant="h6">RevisionNotes Notes</Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        // className="question-text"
                        dangerouslySetInnerHTML={{
                          __html: revisionNotes,
                        }}
                      />
                    </Paper>
                  )} */}
                {/* {isAdmin &&
                  revisionNotes &&
                  revisionNotes.length > 0 &&
                  answerSubmitted &&
                  !hideDesriptions && (
                    <Paper className="question-content">
                      <Typography variant="h6">RevisionNotes Notes</Typography>

                      <TinyView value={revisionNotes} />
                    </Paper>
                  )} */}

                {helpfulLinks &&
                  helpfulLinks.length > 0 &&
                  answerSubmitted &&
                  !hideDesriptions && (
                    <Paper className="question-content">
                      <Typography variant="h6">Helpful Links</Typography>
                      {helpfulLinks.map((item, k) => (
                        <span key={k}>
                          {item.link &&
                            (item.link.match(/^http/) ? (
                              <Link href={item.link} target="_blank">
                                {" "}
                                {item.reason}
                              </Link>
                            ) : (
                              <div className="iframe-container">
                                <span
                                  className="question-text"
                                  dangerouslySetInnerHTML={{
                                    __html: item.link,
                                  }}
                                />
                              </div>
                            ))}
                          <br />
                        </span>
                      ))}
                    </Paper>
                  )}

                {isAdmin && answerSubmitted && !hideDesriptions && (
                  <div className={classes.root}>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Study Notes" {...a11yProps(0)} />

                        <Tab label="Revision Notes" {...a11yProps(1)} />

                        <Tab label="Explanation Notes" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={value} index={0} dir={theme.direction}>
                        {_description && _description.length > 0 && (
                          <>
                            <Typography variant="h6">Study Notes</Typography>
                            {/* <span
                              className="question-text"
                              dangerouslySetInnerHTML={{
                                __html: _description,
                              }}
                            /> */}
                            <TinyView value={_description} />
                          </>
                        )}
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction}>
                        {revisionNotes && revisionNotes.length > 0 && (
                          <>
                            <Typography variant="h6">
                              RevisionNotes Notes
                            </Typography>
                            <TinyView value={revisionNotes} />
                          </>
                        )}
                      </TabPanel>
                      <TabPanel value={value} index={2} dir={theme.direction}>
                        {explanationNotes && explanationNotes.length > 0 && (
                          <>
                            <Typography variant="h6">
                              ExplanationNotes Notes
                            </Typography>
                            <TinyView value={explanationNotes} />
                          </>
                        )}
                      </TabPanel>
                    </SwipeableViews>
                  </div>
                )}

                {answerSubmitted && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={nextQuestion}
                      className="float-right-button"
                    >
                      Next Question
                      <NavigateNextIcon />
                    </Button>
                    <Button
                      className={"float-right-button"}
                      onClick={() => {
                        setReportmodal(true);
                      }}
                    >
                      <ReportIcon /> {"  "}Feedback
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  align="center"
                  color="textPrimary"
                  gutterBottom
                >
                  Collecting Questions..
                </Typography>
                <LinearProgress variant="query" />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              className="ref-button"
              aria-describedby={id}
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleRefPanelClick}
            >
              reference values
            </Button>
            <Popover
              id={refId}
              open={openRefPanel}
              anchorEl={anchorEl}
              onClose={handleClose}
              className="ref-body"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Reference />
            </Popover>

            <Paper className="question-content question-list">
              <Typography variant="subtitle2">Question List</Typography>
              <List dense>
                {submissions &&
                  qIds.map((id, i) => (
                    <ListItem
                      key={i}
                      className={currentQ === i ? "current-item" : ""}
                      button
                      onClick={() => {
                        setCurrentQuestion(i);
                      }}
                    >
                      <ListItemAvatar>
                        <span>{i + 1}</span>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            {submissions[id] ? (
                              hideDesriptions ? (
                                "Answered"
                              ) : (
                                <Chip
                                  size="small"
                                  avatar={
                                    <Avatar>
                                      {submissions[id].correct ? (
                                        <CheckIcon />
                                      ) : (
                                        <ClearIcon />
                                      )}
                                    </Avatar>
                                  }
                                  label={
                                    submissions[id].correct
                                      ? "Correct"
                                      : "Wrong"
                                  }
                                  className={`${
                                    submissions[id].correct
                                      ? "correct"
                                      : "wrong"
                                  } chip`}
                                />
                              )
                            ) : (
                              <span>-- --</span>
                            )}
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        {pastPaperQuestions[id] && (
                          <Tooltip title="This is a high yield or a recall question.">
                            <StarsIcon />
                          </Tooltip>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
        {exam && (
          <ExamModal
            match={match}
            exam={exam}
            expired={expiredExam}
            submissions={submissions}
          />
        )}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snackBar.open}
          autoHideDuration={4000}
          onClose={handleCloseSnackBar}
          message={<span id="message-id">{snackBar.msg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <ReportModal
          open={reportmodal}
          onClose={reportModelClosed}
          question={fullQuestions[currentQ]}
        />
      </Container>
      <Footer />
    </>
  );
};

export default Questions;
