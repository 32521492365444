import React from "react";
import { Typography } from "@material-ui/core";
import PricingGrid from "../../components/PricingGrid";

const Index = props => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 930 63"
        className="price-border"
      >
        <path
          fill="#0277bd"
          fillRule="evenodd"
          d="M0 38.4c18.8 2.4 33 3.7 42.6 3.9 28.6.6 57.8-2.1 87-3.9 65.8-4 85.5-23.6 197.2-24C438.6 13.9 444 37.3 548 37c104-.3 142.5-21.8 195.2-16.8 35.2 3.4 97.4 17.6 186.8 42.6V.5H0"
        />
      </svg>
      <div className="price-section" id="pricing">
        <div className="price-container">
          <Typography
            component="h2"
            className="section-title"
            align="center"
            gutterBottom
          >
            Exams and Pricing
          </Typography>
          <Typography
            className="pricing-subtitle"
            variant="button"
            align="center"
            component="p"
            gutterBottom
          >
            Fair and transparent pricing ● No hidden fees ever ● No Commitments
          </Typography>
          <PricingGrid />
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 930 63"
        className="price-border-reverse"
      >
        <path
          fill="#0277bd"
          fillRule="evenodd"
          d="M0 38.4c18.8 2.4 33 3.7 42.6 3.9 28.6.6 57.8-2.1 87-3.9 65.8-4 85.5-23.6 197.2-24C438.6 13.9 444 37.3 548 37c104-.3 142.5-21.8 195.2-16.8 35.2 3.4 97.4 17.6 186.8 42.6V.5H0"
        />
      </svg>
    </>
  );
};
export default Index;
