import React from "react";
import { Typography, Grid, Fab } from "@material-ui/core";
import ReferralImg from "../../images/referral.svg";

const Referral = () => {
  return (
    <>
      <div className="referral-section home-page-section" id="referral">
        <div className="referral-container">
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <img className="referral-img" src={ReferralImg} />
            </Grid>
            <Grid item xs={12} sm={6} className="referral-container">
              <Typography
                component="h2"
                className="section-title"
                align="center"
                gutterBottom
              >
                Referral Program
              </Typography>
              <Typography variant="h5" align="center" gutterBottom>
                Earn $US 5 everytime when someone signs up with your referral
                link!.
              </Typography>
              <br />
              <div>
                <Fab variant="extended" color="secondary" href="/auth">
                  Get started now!
                </Fab>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default Referral;
