export const getRevisionQuestions = revisionQuestions => {
  // 1 day, 7 days, 21 days, 2 months
  const inQueue = [],
    completed = [],
    one = [],
    seven = [],
    sixTeen = [],
    thirtyFive = [];

  const daysec = 60 * 60 * 24;
  const sevenDay = daysec * 7;
  const sixTeenDay = daysec * 16;
  const thirtyFiveDay = daysec * 35;

  Object.keys(revisionQuestions || {}).forEach(questionKey => {
    const question = revisionQuestions[questionKey];
    const conut = Object.keys(question.count || {}).length;
    const today = Date.now() / 1000;
    const delta = today - question.updatedAt.seconds;
    if (delta < daysec) {
      inQueue.push(questionKey);
    } else if (conut === 0) {
      if (delta >= daysec) {
        one.push(questionKey);
      } else {
        inQueue.push(questionKey);
      }
    } else if (conut === 1) {
      if (delta >= sevenDay) {
        seven.push(questionKey);
      } else {
        inQueue.push(questionKey);
      }
    } else if (conut === 2) {
      if (delta >= sixTeenDay) {
        sixTeen.push(questionKey);
      } else {
        inQueue.push(questionKey);
      }
    } else if (conut === 3) {
      if (delta >= thirtyFiveDay) {
        thirtyFive.push(questionKey);
      } else {
        inQueue.push(questionKey);
      }
    } else {
      completed.push(questionKey);
    }
  });
  return {
    inQueue,
    completed,
    one,
    seven,
    sixTeen,
    thirtyFive
  };
};
