import React from "react";
import { Typography, Paper, CircularProgress } from "@material-ui/core";
import { Bar } from "react-chartjs-2";

import "./index.scss";

const Progres = props => {
  const { submissions, topics } = props;
  const labels = Object.values(topics).map(t => t.name);
  let data = {};
  const correct = [];
  const wrong = [];
  const unanswered = [];

  if (labels.length > 0) {
    Object.keys(topics || {}).map(topicId => {
      if (topicId !== "lastAttempted") {
        let c = 0;
        let w = 0;
        let t = Object.keys(topics[topicId].questions || {}).length;
        Object.keys(submissions[topicId] || {}).map(questionId => {
          if (submissions[topicId][questionId].correct) {
            c += 1;
          } else {
            w += 1;
          }
        });
        correct.push(Math.floor((c / t) * 100));
        wrong.push(Math.floor((w / t) * 100));
       return  unanswered.push(Math.floor(((t - c - w) / t) * 100));
      }
    });

    data = {
      labels: labels,
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      },
      datasets: [
        {
          label: "Correct Answers",
          data: correct,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1
        },
        {
          label: "Wrong Answers",
          data: wrong,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1
        },
        {
          label: "Unanswered",
          data: unanswered,
          backgroundColor: "rgba(0,0,0 ,0.04)",
          borderColor: "rgba(0,0,0 ,0.1)",
          borderWidth: 1
        }
      ]
    };
  }

  return (
    <>
      <Paper className="dashbaord-container progress">
        <Typography variant="h6" gutterBottom align="left" color="primary">
          Progress
        </Typography>
        <div className='progress-wrapper'>
          {labels.length > 0 ? (
            <Bar
              data={data}
              width={100}
              height={250}
              options={{
                maintainAspectRatio: false,
                title: {
                  display: true,
                  text: "Percentage (%) Complete"
                },
                scales: {
                  xAxes: [{ stacked: true }],
                  yAxes: [{ stacked: true }]
                }
              }}
            />
          ) : (
            <>
              <CircularProgress />
              <Typography variant="subtitle1" color="textSecondary">
                Building your progress Graph
              </Typography>
            </>
          )}
        </div>
      </Paper>
    </>
  );
};
export default Progres;
