import React, { useState } from "react";
import { Grid, Container, Paper, Typography } from "@material-ui/core";
import "./index.scss";

const refValues = {
  "Full blood count": {
    Haemoglobin: `Male: "13.0-18.0 g/dl (130-180 g/L)" <br/> Female: "11.5-16.0 g/dl (115-160 g/L)"`,
    "Mean cell volume (MCV)": "76-96 fL",
    "Mean cell haemoglobin Concentration (MCHC)": "300-360 g/L",
    "Packed red cell volume (PCV) or haematocrit":
      "Female: 0.37-0.47 L/L <br/> Male: 0.4-0.54 L/L",
    "Red cell count":
      "Male: 4.5-6.5x 10<sup>12</sup>/L <br/> Female: 3.9-5.6x 10<sup>12</sup>/L",
    Platelets: "150-400x 10<sup>9</sup>/L",
    "White cell count (WCC) (total)": "4-11 X 10<sup>9</sup>/L",
    "WCC Neutrophils": "40-75% (2-7.5 x 10<sup>9</sup>/L)",
    "WCC Eosinophils": "1-6% (0.04-0.44 x 10<sup>9</sup>/L)",
    "WCC Basophils": "0-1% (0.0-0.10 x 10<sup>9</sup>/L)",
    "WCC Lymphocytes": "20-45% (1.3-3.5x 10<sup>9</sup>/L)"
  },

  "Urea and electrolytes": {
    Sodium: "135-145 mmol/L",
    Potassium: "3.5-5.0 mmol/L",
    Urea: "2.0-7 mmol/L",
    Creatinine: "70-150 umol/L",
    Bicarbonate: "22-26 mmol/L",
    Chloride: "95-105 mmol/L",
    EGFR: "Greater than 90"
  },

  "Liver function tests": {
    Bilirubin: "3-17 umol/L",
    "Alanine transferase (ALT)": "5-35 U/L",
    "Aspartate transaminase (AST)": "5-35 U/L",
    "Alkaline phosphatase (ALP)": "30-150 U/L",
    "Gamma glutamyl transferase (yGT)": "8-60 U/L",
    Albumin: "35-50 g/L",
    "Total protein": "60-80 g/L"
  },

  "Haematology tests": {
    "Erythrocyte sedimentation rate (ESR)": "< age/2 mm/hour",
    "Prothrombin time (PT)": "10-14 secs",
    "Activated partial thromboplastin time (APTT)": "35-45 secs",
    "Bleeding time": "3-9 minutes",
    Fibrinogen: "2.0-4.0 g/L",
    Ferritin: "20-300 ng/ml",
    "Vitamin B12": "0.13-0.68 nmol/L (150-700ng/L)",
    Folate: "2.0 -11.0 mcg/L",
    "Vitamin D": "60-105 nmol/L",
    Reticulocytes: "0.8-2.0% (25-100 x 10<sup>9</sup>/L)",
    "D-Dimer": "< 500 ng/mL",
    "International normalized ratio (INR)": "0.8-1.2"
  },

  "Other biochemistry and hormones": {
    "Calcium (Total)": "2.1-2.6 mmol/L",
    Phosphate: "0.8-1.4 mmol/L",
    Magnesium: "0.75-1.05 mmol/L",
    CRP: "< 10 mg/L",
    "Thyroid stimulating hormone (TSH)": "0.5-5.7 mU/L",
    "Free thyroxine (T4)": "9-18 pmol/L",
    "Total thyroxine (T4)": "70-140 nmol/L",
    "Free triiodothyronine (T3)": "3.5-6.5 pmol/L",
    "Total triiodothyronine (T3)": "0.9-2.8 nmol/L",
    "Thyroxine-binding globulin (TBG)": "7-17 mg/L",
    "Total iron-binding capacity": "54-75 umol/L",
    "Parathyroid hormone (PTH)": "< 0.8-8.5 pmol/L",
    Amylase: "0-140 U/L",
    "Uric acid (Urate)":
      "Male: 210-480 umol/L (3.5-8 mg/dL)<br>Female: 150-390 umol/L (2.5-6.5 mg/dL)",
    "Prostate-specific antigen (PSA)": "0-4 ng/mL",
    "17 hydroxyprogesterone (female)": "0.2-1 mg/L",
    "Adrenocorticotropic hormone(ACTH)": "4.5-20 pmol/L",
    "Follicle-stimulating hormone (FSH)":
      "Male: 1-10 U/L<br/>Female (luteal): 2-8 U/L<br/>Female (ovulation): 5-25 U/L<br/>Female (postmenopause): >25U/L",
    "Luteinizing Hormone (LH)":
      "Male Prepubertal: 0.15 U/L <br/> Male Adult: 0.5-9 U/L <br/> Female (luteal): 3-16 U/L <br/> Female (ovulation): 16-84 U/L <br/> Female (postmenopause): 17-75 U/L",
    Oestradiol:
      "Male: 50-200 pmol/L<br/> Female: (postmenopause): < 130 pmol/L",
    "Growth hormone": "< 20 mu/L",
    Progesterone: "Women (ovulation): 70-280 ng/dL",
    Prolactin:
      "Female: < 440 mU/L (< 25 ng/mL)<br/>Male: < 350 mU/L (< 20 ng/mL)",
    Testosterone: "Male: 10-25 nmol/L",
    "Glucose (fasting)": "3.5-5.5 mmol/L",
    "HbA1c glycosylated Hb (DCCT)": "4-6%. 7% = Good Diabetic control",
    "Cortisol (Blood)": "AM: 450-700 nmol/L<br/>Midnight: 80-280 nmol/L",
    Lactate: "Venous: 0.6-2.4 mmol/L<br/>Arterial: 0.6-1.8 mmol/L"
  },
  "Arterial blood gases": {
    pH: "7.35-7.45",
    РCО2: "4.7-6.0 kPa (35-45 mmHg)",
    pO2: "10-14 kPa (75-100 mmHg)",
    "Base excess": "+/- 2 mmol/L"
  },
  Lipids: {
    "Total cholesterol": "< 5 mmol/L",
    Triglycerides: "0.55-1.90 mmol/L",
    "HDL cholesterol": "0.9-1.93 mmol/L",
    "LDL cholesterol": "< 2 mmol/L"
  },
  "Cardiac enzymes": {
    "Troponin T": "< 0.1ug/L",
    "Creatine kinase": "Male: 25-195 U/L<br/>Female: 5-170 U/L",
    "Lactate dehydrogenase (LDH)": "70-250 U/L"
  },
  "Urine reference intervals": {
    "Cortisol (free)": "280 nmoI/24h"
  }
};

const Reference = props => {
  const [refSearch, setRefSearch] = useState("");

  const updateRefs = e => {
    setRefSearch(e.target.value);
  };
  return (
    <Paper elevation={8} className="ref-body">
      <Container>
        <input
          autoFocus
          placeholder="Search"
          onChange={updateRefs}
          className="ref-search"
        />
        {Object.keys(refValues || {}).map((headerKey, hk) => (
          <span key={hk}>
            <Typography variant="h6" gutterBottom className="ref-title">
              {headerKey}
            </Typography>
            {Object.keys(refValues[headerKey] || {}).map(
              (key, k) =>
                key
                  .toLocaleLowerCase()
                  .match(refSearch.toLocaleLowerCase()) && (
                  <Grid
                    container
                    spacing={4}
                    key={`${hk}_${k}g1`}
                    className="ref-item"
                  >
                    <Grid item xs={4}>
                      {key}
                    </Grid>
                    <Grid item xs={8}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: refValues[headerKey][key]
                        }}
                      />
                    </Grid>
                  </Grid>
                )
            )}
          </span>
        ))}
      </Container>
    </Paper>
  );
};
export default Reference;
