import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import withRoot from "./withRoot";
import { withStyles } from "@material-ui/core/styles";
import * as serviceWorker from "./serviceWorker";
const queryString = require("query-string");

const styles = theme => ({});

const AppWithRoot = withRoot(withStyles(styles)(App));
const parsed = queryString.parse(window.location.search);

if (parsed.ref) {
  window.localStorage.setItem("ref", parsed.ref);
}

ReactDOM.render(<AppWithRoot />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
