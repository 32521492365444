import "./index.scss";

import { Button } from "@material-ui/core";
import ExamNotes from "../../images/home/exam_notes.svg";
import ExamSmart from "../../images/home/exam_smart.svg";
import ExamStyle from "../../images/home/exam_style.svg";
import ExamTrack from "../../images/home/exam_track.svg";
import Fab from "@material-ui/core/Fab";
import Footer from "../../components/Footer";
import Grid from "@material-ui/core/Grid";
import Particles from "react-particles-js";
import Pricing from "./Pricing";
import PropTypes from "prop-types";
import React from "react";
import Referral from "./Referral";
import Typography from "@material-ui/core/Typography";
import particlesProps from "../particles";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    position: "relative",
    height: "700px",
    // backgroundColor: theme.palette.primary.main,
    textAlign: "center",
  },
  heroTexts: {
    maxWidth: "800px",
    minWidth: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "z-index": 2,
  },
  title: {
    color: "#fff",
    fontFamily: "'Merriweather', serif",
    fontWeight: 500,
  },
  particles: {
    position: "absolute",
    width: "100%",
    height: "calc(100% - 130px)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "z-index": 1,
  },
});

const useCases = [
  {
    title: "Perfectly tailored exam material.",
    image: ExamNotes,
    points: [
      "Detailed explanations to ensure you can answer all exam questions with ease.",
      "Updated notes that align with relevant guidelines.",

      "Graphs, images, X-Rays, ECGs and other visuals to give you a comprehensive understanding of every topic.",
    ],
  },

  {
    title: "Exam style questions.",
    image: ExamStyle,
    points: [
      "3000+ exam style questions for each exam.",
      "New questions added frequently to constantly challenge you.",
      "Answers and explanations provided for every question.",
      "Save questions to use with the smart revision tool.",
    ],
  },
  {
    title: "Smart revision",
    image: ExamSmart,
    points: [
      "Detects areas that need more revision and spaces out questions in calculated intervals for optimal memory retention.",
      "Manually add questions to be circulated into your learning.",
    ],
  },
  {
    title: "Track your progress",
    image: ExamTrack,
    points: [
      "Compare your scores with other users.",
      "Track your progress to face your exam with confidence.",
    ],
  },
];

const Index = (props) => {
  const { classes } = props;

  return (
    <>
      <div className={`hero ${classes.root}`}>
        <Particles
          className={classes.particles}
          width="100%"
          height="100%"
          params={particlesProps}
        />
        <div className={classes.heroTexts}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            className={classes.title}
            gutterBottom
          >
            The most effective revision tool for{" "}
            <span className="seconadry">medical exams </span>
          </Typography>

          <Typography variant="h6" gutterBottom>
            Practice with more than 3500 questions, check out detailed answers
            and explanations, track your progress and cover all your bases with
            our smart revision tools.
          </Typography>
          <Fab variant="extended" color="secondary" href="/auth">
            Get started now!
          </Fab>
          <br />
          <br />
          <Button color="secondary" href="/demo/plab">
            See sample PLAB questions
          </Button>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 930 63"
          className="hero-border"
        >
          <path
            fill="#0277bd"
            fillRule="evenodd"
            d="M0 38.4c18.8 2.4 33 3.7 42.6 3.9 28.6.6 57.8-2.1 87-3.9 65.8-4 85.5-23.6 197.2-24C438.6 13.9 444 37.3 548 37c104-.3 142.5-21.8 195.2-16.8 35.2 3.4 97.4 17.6 186.8 42.6V.5H0"
          />
        </svg>
      </div>

      <div className="home-page-section use-cases" id="features">
        {useCases.map((usecase, u) => (
          <Grid container className="use-cases-section" key={u}>
            <Grid item className="image-wrapper" xs={12} md={6}>
              <img alt={usecase.title} src={usecase.image} />
            </Grid>
            <Grid item className="text" xs={12} md={6}>
              <Typography variant="h2" gutterBottom className="title">
                {usecase.title}
              </Typography>

              {usecase.points.map((point, p) => (
                <div key={p}>
                  <span className="check-mark"> ✔ </span>
                  <Typography variant="h5" gutterBottom>
                    {point}
                  </Typography>
                </div>
              ))}
            </Grid>
          </Grid>
        ))}
      </div>

      <Pricing />
      <Referral />
      <Footer />
    </>
  );
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
