import "./index.scss";

import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { firebase, firestore } from "../../firebase";

import Checkout from "./Checkout";
import { Elements } from "react-stripe-elements";
import LinearProgress from "@material-ui/core/LinearProgress";
import Particles from "react-particles-js";
import PricingGrid from "../../components/PricingGrid";
import { StripeProvider } from "react-stripe-elements";
import axios from "axios";
import particlesProps from "../particles";

const stripeAPIKEY = "pk_live_eipbFT2Cpqee5C2yYgNpIKKL00oNt4500B";
const HOST = " https://us-central1-medicalapp-ad2fc.cloudfunctions.net";

const Auth = (props) => {
  const [firebaseuiLoaded, setFirebaseuiLoaded] = useState(false);
  const [stripe, setStripe] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [authedUser, setAuthedUser] = useState(null);
  const [subExpired, setSubExpired] = useState(null);
  const [sku, setSku] = useState(
    window.location.search.match(/sku=/) &&
      window.location.search.replace(/\?sku=/, "")
  );
  const refToken = window.localStorage.getItem("ref");

  if (!authedUser) {
    const firebaseui = require("firebaseui");
    const uiConfig = {
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          signInSuccessCallBack(authResult);
          return false;
        },
        uiShown: () => {
          setFirebaseuiLoaded(true);
        },
      },
      signInFlow: "popup",
      tosUrl: "/terms",
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      privacyPolicyUrl: function () {
        window.location.assign("/privacy");
      },
    };
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authedUser) => {
      if (authedUser) {
        checkUser(authedUser);
      }
    });
    if (window.Stripe) {
      setStripe(window.Stripe(stripeAPIKEY));
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        setStripe(window.Stripe(stripeAPIKEY));
      });
    }
  }, []);

  const signInSuccessCallBack = (authResult) => {
    checkUser(authResult.user);
  };

  const checkRef = async () => {
    if (refToken) {
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.post(
        `${HOST}/verifyRefToken`,
        {
          refToken,
        },
        { headers: { authorization: "Bearer " + token } }
      );
      if (response.data.validReferal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const onPlanClicked = (sku) => {
    setSku(sku);
  };

  const checkUser = async (user) => {
    setCheckingStatus(true);
    const usersRef = firestore.collection("users").doc(user.uid);
    const subsRef = firestore.collection("subscriptions").doc(user.uid);

    const userState = {
      id: user.uid,
      name: user.displayName,
      email: user.email,
      photoUrl: user.photoURL,
      emailVerified: user.emailVerified,
    };
    const userSnap = await usersRef.get();

    if (!userSnap.exists) {
      const validRef = await checkRef();
      if (validRef) {
        userState.refFrom = refToken;
      }
      await usersRef.set(userState, { merge: true });
      setCheckingStatus(false);
      setAuthedUser(userState);
    } else {
      const subSnap = await subsRef.get();
      setCheckingStatus(false);
      setAuthedUser(userState);
      if (!subSnap.exists) {
        const validRef = await checkRef();
        if (validRef) {
          userState.refFrom = refToken;
        }
      } else {
        const sub = subSnap.data();
        if (sub.periodEnd < new Date().getTime()) {
          setSubExpired(new Date(sub.periodEnd));
        } else {
          window.location.href = "/dashboard";
        }
      }
    }
  };

  const showDiscount = authedUser && authedUser.refFrom;
  particlesProps.particles.line_linked.color = "#0277bd";
  particlesProps.particles.line_linked.opacity = 0.7;

  return (
    <Grid container className="auth">
      {/* <Hidden xsDown> */}
      <Grid item sm={6} className="auth-left">
        <Particles className="particles" params={particlesProps} />
        <div className="text-wrapper">
          <Typography variant="h2" gutterBottom>
            {authedUser ? `Hello ${authedUser.name}!` : "Lets get started!"}
          </Typography>
          <Typography variant="h6">
            {authedUser
              ? ""
              : "You can create an account or sign in using an email address or a social account"}
          </Typography>
          {showDiscount && (
            <>
              <Typography
                variant="button"
                className="discount-text"
                gutterBottom
              >
                Thank you for accepting the referal offer.
              </Typography>
              <br />
              <br />
              <Typography
                variant="button"
                className="discount-text"
                gutterBottom
              >
                <strong> Enjoy a 10% discount! </strong>
              </Typography>
            </>
          )}
        </div>
      </Grid>
      {/* </Hidden> */}

      <Grid item xs={12} sm={6} className="auth-right">
        <div className="auth-content">
          {!firebaseuiLoaded && !sku && <LinearProgress color="secondary" />}

          {authedUser &&
            (checkingStatus ? (
              <div>
                <Typography variant="subtitle1" color="textSecondary">
                  Checking for subscriptions...
                </Typography>
                <LinearProgress color="secondary" />
              </div>
            ) : sku ? (
              <StripeProvider stripe={stripe}>
                <Elements>
                  <Checkout user={authedUser} sku={sku} />
                </Elements>
              </StripeProvider>
            ) : (
              <>
                <Typography variant="h4" gutterBottom align="center">
                  {subExpired
                    ? "Select a plan to renew your membership"
                    : "Pick a plan to get started"}
                </Typography>

                <br />

                {subExpired && (
                  <Typography className="error-message" variant="h6">
                    Your subscription was expired on{" "}
                    {subExpired.toLocaleDateString()}{" "}
                    {subExpired.toLocaleTimeString()}. Please select a plan to
                    renew your subscription.
                  </Typography>
                )}
                <br />
                <PricingGrid
                  showDiscount={showDiscount}
                  onPlanClicked={onPlanClicked}
                />
              </>
            ))}
          <div
            id="firebaseui-auth-container"
            style={{ display: authedUser ? "none" : "block" }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Auth;
