import firebase from "firebase/app";
require("firebase/firestore");
require("firebase/storage");
require("firebase/auth");

const config = {
  apiKey: "AIzaSyCsDs1rPKQx6qww3-HUzKHgoTSoUyPBvh4",
  authDomain: "medicalapp-ad2fc.firebaseapp.com",
  databaseURL: "https://medicalapp-ad2fc.firebaseio.com",
  projectId: "medicalapp-ad2fc",
  storageBucket: "",
  messagingSenderId: "419983079558"
};
// var config = {
//   apiKey: "AIzaSyBpSBxZFJ2q1KIuGcWO5GX7APxgylLRWeE",
//   authDomain: "medrevisions-qa.firebaseapp.com",
//   projectId: "medrevisions-qa",
//   storageBucket: "medrevisions-qa.appspot.com",
//   messagingSenderId: "209633167549",
//   appId: "1:209633167549:web:8f23a50e52a44cbbfe4fd4",
//   measurementId: "G-9KJQHBCLYK"
// };
// var config = {
//   apiKey: "AIzaSyBpSBxZFJ2q1KIuGcWO5GX7APxgylLRWeE",
//   authDomain: "medrevisions-qa.firebaseapp.com",
//   projectId: "medrevisions-qa",
//   storageBucket: "medrevisions-qa.appspot.com",
//   messagingSenderId: "209633167549",
//   appId: "1:209633167549:web:7be5954d1668bff8fe4fd4",
//   measurementId: "G-ND8EG1ETPH"
// };

firebase.initializeApp(config);

const firestore = firebase.firestore();
// const storage = firebase.app().storage('gs://uploadmystock-staging-uploads')

// const PROJECT_ID = 'uploadmystock-staging'
// const STRIPE_ID = 'pk_test_Do2tn3cwJGOokUNODikAEbKL' //rename-file PROJECT_ID, STRIPE_ID
export { firebase, firestore };
