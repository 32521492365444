import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogContentText,
  FormHelperText
} from "@material-ui/core";
import { firestore, firebase } from "../../firebase";

const ReportModal = ({ open, onClose, question }) => {
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    firebase.auth().onAuthStateChanged(user => {
      firestore
        .collection("feedback")
        .add({
          questionId: question.id,
          title: question.title,
          topic: question.topic,
          subTopic: question.subTopic,
          uid: user.uid,
          email: user.email,
          feedback,
          date: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
          setLoading(false);
          onClose(true);
        });
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Question Feedback"}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText>
            Thank you for taking the time to provide your feedback on this
            question. Please fill in the form below and submit
          </DialogContentText>
          <TextField
            onChange={e => setFeedback(e.target.value)}
            required
            autoFocus
            label="Feedback"
            fullWidth
          />
          <FormHelperText>Please be detailed as possible</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" type="submit" disabled={loading}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReportModal;
