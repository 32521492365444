import React, { useState, useEffect } from "react";
import "./index.scss";
import { firestore, firebase } from "../../firebase";

import {
  Typography,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";

import Education from "@material-ui/icons/CastForEducation";
import Money from "@material-ui/icons/AttachMoney";
import Link from "@material-ui/icons/Link";

function createData({ refFor, date, credit }) {
  return {
    email: refFor,
    time: new Date(date.seconds * 1000).toDateString(),
    amount: credit
  };
}


function createPayoutData({ payPalEmail, requestedOn, payoutProcessedOn, amount }) {
  return {
    requestedOn: new Date(requestedOn.seconds * 1000).toDateString(),
    payoutProcessedOn: payoutProcessedOn ? new Date(payoutProcessedOn.seconds * 1000).toDateString() : 'Pending',
    payPalEmail,
    amount
  };
}

const Profile = props => {
  const [dialog, setDialog] = useState(false);
  const [credits, setCredits] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false)
  const [payPalEmail, setPayPalEmail] = useState(null);
  const [payPalEmailConf, setPayPalEmailConf] = useState(null);


  const { name, photoUrl, refToken, id } = props.user;
  const { skuName, periodEnd } = props.subscription || {};
  const expiresOn = new Date(periodEnd);
  const handleClose = () => {
    setDialog(false);
  };

  const fetchTrans = () => {
    firestore
      .collection("referrals")
      .doc(id)
      .collection("credits")
      .get()
      .then(snap => {
        const credits = [];
        let bal = 0;
        snap.forEach(s => {
          const data = s.data()
          credits.push(createData(data));
          bal += data.credit
        });
        setCredits(credits);

        firestore
          .collection("referrals")
          .doc(id)
          .collection("payouts")
          .get()
          .then(snap => {
            const payouts = [];
            snap.forEach(s => {
              const data = s.data()
              payouts.push(createPayoutData(data));
              bal -= data.amount
            });
            setPayouts(payouts);
            setBalance(bal)
          });
      });
  }

  const requestPayout = (e) => {
    e.preventDefault();
    setLoading(true)
    firestore
      .collection("referrals")
      .doc(id)
      .collection("payouts")
      .add({
        requestedOn: firebase.firestore.FieldValue.serverTimestamp(),
        amount: balance,
        payPalEmail
      })
      .then(() => {
        setLoading(false)
        fetchTrans()
      });
  }

  useEffect(() => {
    if (id) {
      fetchTrans()
    }
  }, [id]);



  return (
    <Paper className="dashbaord-container profile">
      <Typography variant="h6" gutterBottom align="left" color="primary">
        Profile
      </Typography>
      <Avatar src={photoUrl} className="avatar" />
      <Typography variant="h5">Hello {name}</Typography>

      <List component="nav">
        <ListItem>
          <ListItemIcon>
            <Education />
          </ListItemIcon>
          <ListItemText
            primary="Subscription"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {skuName}
                </Typography>
                {" — Expires on: " + expiresOn.toDateString()}
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Money />
          </ListItemIcon>
          <ListItemText
            primary="Referral Account"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  Balance:
                </Typography>
                {` - US$ ${(props.referal || {}).balance || 0}`}
              </>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={() => setDialog(true)}>
              <Link />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Dialog open={dialog} onClose={handleClose}>
        <form onSubmit={requestPayout}>
          <DialogTitle>{"Referral Account"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>
                {" "}
                Referral Link: {` — ${
                  window.location.origin
                  }?ref=${refToken}`}{" "}
              </strong>
              <br />
              <br />
              Below is a transection summary of your account
          </DialogContentText>
            <Typography variant='subtitle2'>Referral Credits</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Credit Amount (US$)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {credits.map((row, r) => (
                  <TableRow key={r}>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell>{row.time}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Typography variant='subtitle2'>Payouts</Typography>
            {payouts.length > 0 ? <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell> Paypal Email</TableCell>
                  <TableCell>Requested On</TableCell>
                  <TableCell>Completed On</TableCell>
                  <TableCell align="right">Payout Amount (US$)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payouts.map((row, r) => (
                  <TableRow key={r}>
                    <TableCell component="th" scope="row">
                      {row.payPalEmail}
                    </TableCell>

                    <TableCell>{row.requestedOn}</TableCell>
                    <TableCell>{row.payoutProcessedOn}</TableCell>

                    <TableCell align="right">{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table> : "No payouts yet"}

            <br />
            <Typography variant='h6'>Paypal Account Information - Balance: US$ {balance}</Typography>
            <Typography variant='subtitle2'>You can request a payout to your paypal account when you have a balance of greater than US$ 20</Typography>

            <TextField
              required
              label="PayPal Email"
              margin="normal"
              type='email'
              onChange={(e) => setPayPalEmail(e.target.value)}
            />
            <br />
            <TextField
              required
              label="Confirm PayPal Email"
              margin="normal"
              type='email'
              onChange={(e) => setPayPalEmailConf(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
          </Button>
            <Button onClick={handleClose} color="primary" disabled={payPalEmail === null || payPalEmail !== payPalEmailConf || balance < 20 || loading} type='submit'>
              Transfer to Paypal
          </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
};
export default Profile;
