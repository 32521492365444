import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  LinearProgress,
  Typography,
  CircularProgress
} from "@material-ui/core";
import "./index.scss";
import { firestore, firebase } from "../../firebase";

const ExamModal = props => {
  const { match, exam, expired, submissions } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const startExam = () => {
    if (exam.startedAt) {
      setOpen(false);
    } else {
      setLoading(true);
      firestore
        .collection("timedExams")
        .doc(match.params.topicOrExamId)
        .set(
          { startedAt: firebase.firestore.FieldValue.serverTimestamp() },
          { merge: true }
        )
        .then(() => {
          setLoading(false);
          setOpen(false);
        });
    }
  };

  const browseExam = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (match.path.match(/exam/)) {
      setOpen(true);
    }
  }, []);
  const score = Object.values(submissions || {}).filter(s => s.correct).length;
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Timed Exam</DialogTitle>
      <DialogContent>
        {!exam && <LinearProgress variant="indeterminate" />}
        <DialogContentText id="alert-dialog-description">
          {!exam ? (
            <span>Preparing your exam...</span>
          ) : expired ? (
            <>"This exam has ended. You can review your past answers"</>
          ) : exam.startedAt ? (
            "This exam is already in progress. Click continue to answer questions"
          ) : (
            `For this exam you'll have ${exam.time} Minutes to answer all ${
              exam.questions.length
            } Questions. Once your start the exam you wont be able to pause it. Make sure you are in a comfortable space and wont be interupted.`
          )}
        </DialogContentText>

        {expired && (
          <Typography variant="h6" align="center">
            Score: {score} of {exam.questions.length} - (
            {Math.floor((score / exam.questions.length) * 100)}%)
          </Typography>
        )}
      </DialogContent>
      {exam && (
        <DialogActions>
          <Button href="/dashboard" color="primary" disabled={loading}>
            {expired || exam.startedAt
              ? "Back to Dashboard"
              : "Come back later"}
          </Button>
          {expired ? (
            <Button onClick={browseExam} color="primary" disabled={loading}>
              Review Past answers
            </Button>
          ) : (
            <Button onClick={startExam} color="primary" disabled={loading}>
              {exam.startedAt ? (
                "Continue"
              ) : loading ? (
                <CircularProgress />
              ) : (
                "Start the exam"
              )}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
export default ExamModal;
