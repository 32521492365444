import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton,
  ListSubheader
} from "@material-ui/core";
import { firestore, firebase } from "../../firebase";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import DeleteIcon from "@material-ui/icons/Delete";
import "./index.scss";

const Bookmarks = props => {
  const [bookmarks, setBookmarks] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firestore
          .collection("bookmarks")
          .doc(user.uid)
          .get()
          .then(bookmarksSnap => {
            setBookmarks(bookmarksSnap.data());
            setLoading(false);
          });
      }
    });
  }, []);

  const deleteBookmark = id => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        delete bookmarks[id];
        firestore
          .collection("bookmarks")
          .doc(user.uid)
          .set({ ...bookmarks })
          .then(() => {
            setBookmarks({ ...bookmarks });
          });
      }
    });
  };

  const bookmarkIds = Object.keys(bookmarks || {});
  return (
    <>
      <Paper
        className={`dashbaord-container bookmarks ${
          Boolean(bookmarkIds.length) ? "" : "flex"
        } `}
      >
        <Typography variant="h6" gutterBottom color="primary">
          Bookmarks - {bookmarkIds.length}
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : Boolean(bookmarkIds.length) ? (
          <>
            <ListSubheader>
              <Button
                variant="contained"
                className="revise-bookmarks-button"
                color="primary"
                href="/bookmarks"
              >
                Revise all {bookmarkIds.length} questions{" "}
              </Button>
            </ListSubheader>
            <List>
              {bookmarkIds.map((id, b) => (
                <ListItem key={b}>
                  <ListItemText
                    primary={bookmarks[id].subTopic}
                    secondary={bookmarks[id].topic}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => deleteBookmark(id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography color="textSecondary" gutterBottom align="center">
            You dont have any bookmarks yet. Click the <BookmarkIcon /> button
            in a question to save them here
          </Typography>
        )}
      </Paper>
    </>
  );
};
export default Bookmarks;
