import React from "react";
import { Editor as TinyEditor } from "@tinymce/tinymce-react";

export default function TinyView({ value }) {
  return (
    <TinyEditor
      apiKey="utrrpdh2idboibxbi3j835hgnbd2om68gq18mifwf95hysdi"
      value={value}
      init={{
        setup: function (editor) {
          editor.setMode("readonly");
        },
        inline: true,
        plugins: "autoresize",
        toolbar: false,
        menubar: false,
        statusbar: false,
        inline_boundaries: false,
      }}
    />
  );
}
