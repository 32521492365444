import React from "react";

import { Paper, Container, Typography } from "@material-ui/core";
import Footer from "../../components/Footer";
import "./index.scss";

const Questions = () => {
  return (
    <>
      <Container>
        <Paper elevation={1} className="terms-privacy-wrapper">
          <Typography variant="h3" gutterBottom>
            Terms and conditions
          </Typography>
          <br />
          <br />
          <Typography variant="h6" gutterBottom>
            Introduction
          </Typography>
          <Typography>
            Welcome to the Medrevisions.com. This website is a revision website
            for the use of health professionals and medical students. Use of
            this site is conditional on accepting the Terms and Conditions for
            use, please make sure that you read through them carefully and
            understand them before using the Medrevisions website. These Terms
            and Conditions apply to the use of the services provided by Med
            revisions.Inc. If you do not agree to be certain by these Terms and
            Conditions please do not use this website. Note that the Terms and
            Conditions may change over time; those applicable to subscribers are
            those which are current and agreed to when a user subscribes. Both
            the website and the subscription process are in English only. If you
            have inquiries regarding the Terms and Conditions please do contact
            us at contact@medrevisions.com
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>
            Subscription
          </Typography>
          <Typography>
            The price you pay for the services provided by Medrevisions.com is
            indicated on the website. Subscribers are required to make payments
            in US dollars. If you are paying from a non-US dollar account then
            your card provider will calculate the exchange rate of your
            transaction. Please note that if you wish to cancel any
            subscriptions that you have purchased after our contract comes into
            effect, cancellation shall be at our discretion and if accepted may
            be subject to a cancellation charge. You hereby agree that due to
            the nature of the service provided by Medrevisions.Inc that
            cancellation or refund of your subscription is not permitted once
            the service has started. You undertake to us that all the details
            you give to us while using the website are correct, especially that
            the credit or debit card you are using is your own and that there
            are sufficient funds to cover the cost of the service. Also that the
            information your are providing is legit.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>
            Usage of Website
          </Typography>
          <Typography>
            By using our website, whether as a registered user or non-registered
            user, you are indicating that you accept these Terms and Conditions
            and agree to abide by them. Accounts are personal to the subscriber
            and for their sole and non-commercial home use only. Passwords and
            other log on details must be kept private to the user. We have the
            right to take action if this requirement is not adhered to
            including, but not limited to, immediate termination of your
            subscription. If you use or attempt to use the service for purposes
            other than personal, individual revision, including but not limited
            to copying questions, answers or notes, or attempt to tamper with,
            hack, modify or otherwise use the website in any way that is likely
            to compromise or corrupt the security or functionality of the
            service provided by Med Revisions .Inc , your account will be
            terminated and you will be subject to damages and other penalties,
            including criminal prosecution where available. Whilst we will
            endeavor to ensure that the website availability is not interrupted,
            access may be restricted from time to time to allow for site
            maintenance or upgrade. Website availability may also be interrupted
            due to technical problems. Again we will always try to resolve these
            as soon as possible but we cannot accept responsibility for any loss
            or damages that may result from this. If website failure occurs then
            we will extend user accounts by a day for every hour the website is
            unavailable, if this is requested. You can contact us via
            contact@medrevisions.com The website has been optimized to work in
            any modern browsers in any operating system. Use of other equipment
            to access the site may result in restricted functionality.
          </Typography>
          <br />

          <Typography variant="h6" gutterBottom>
            External links
          </Typography>
          <Typography>
            For the convenience of subscribers the Medrevisions website contains
            links to external sites. The inclusion of a link does not imply
            endorsement or approval of the site or its contents which is
            independent of Med Revisions.inc and over which we have no control.
            As such we can accept no responsibility for the contents or any loss
            or damages that may result from using these external sites.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>
            Copyright
          </Typography>
          <Typography>
            The Medrevisions.com website is copyright protected and it's
            content, design, layout, databases or graphics shall not be
            reproduced, stored in any form or by any means including but not
            limited to electronic, paper, mechanical, photocopying, recording,
            or broadcasting, nor shall the site be shown in a public gathering.
            You may only use the material available for your own personal
            non-commercial home use provided that it is not changed and
            copyright notices remain; MedRevisions.Inc retains the intellectual
            property rights on all material from their website. You shall not
            create any derivative work or make any other adaptation, without our
            prior written consent. If you use or attempt to use the service for
            purposes other than personal individual revision, including but not
            limited to copying questions, copying answers, copying notes,
            tampering, hacking, modifying or otherwise corrupting the security
            or functionality of the service provided by MedRevisions.Inc, your
            account will be terminated and you will be subject to damages and
            other penalties, including criminal prosecution where available. All
            rights not explicitly granted in these Terms and Conditions or in
            any written licence remain reserved.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>
            Limitation of liability
          </Typography>
          <Typography>
            This website is provided as a resource to aid revision and makes no
            representations, express or implied, that the drug dosages on the
            website are correct. Users must therefore check with a recognized
            formulary such as the British National Formulary prior to
            prescribing. Whilst reasonable care is taken to ensure the accuracy
            of information on this site, to the fullest extent permitted by law
            the material and information displayed on our website is provided
            without any guarantees, conditions or warranties as to the
            correctness, accuracy, reliability, usefulness or otherwise of
            information provided. Medical knowledge is constantly evolving and
            to the fullest extent permitted by law, Medrevisions Inc does not
            accept any responsibility or legal liability for any errors or the
            misuse or misapplication of material on the website. Medrevisions
            Inc shall not be liable to users of this site by reason of any
            representation or any implied warranty, condition or other term, or
            any duty at common law, or under the express terms of the contract
            for any loss of profit or any direct or indirect, special or
            consequential loss, damage, costs, expenses or other claims of any
            kind (whether caused by the negligence of Medrevisions Inc, its
            servants or agents or otherwise) which arise out of or in connection
            with the revision questions and other information available on this
            website except as expressly provided in these conditions.
            Medrevisions Inc shall not be liable to users or others or be deemed
            in breach of contract by reason of any delay or failure to perform
            any of its obligations in relation to the website if this was due to
            any cause beyond the reasonable control of Medrevisions Inc. In no
            event shall Medrevisions Inc total liability to any user for all
            damages, losses, and other causes of action (whether in contract or
            not) exceed the amount paid to Medrevisions Inc for use of this
            site. The maximum liability for any claim from a user shall not
            exceed the current subscription fee which have been paid.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>
            Privacy
          </Typography>
          <Typography>
            All personal data collected by Medrevisions Inc is governed by our
            privacy policy. By using this site you consent to use of personal
            data in accordance with the privacy policy. Law governing Terms and
            Conditions These Terms and Conditions shall be construed and
            governed exclusively by the laws of the United States of America.
            However we retain the right to bring proceedings for a breach of
            these Terms and Conditions in any relevant country. These terms and
            conditions do not otherwise affect your statutory rights.
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Questions;
