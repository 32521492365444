import React, { useEffect, useState } from "react";
import { Typography, Paper, Grid, Link, Button } from "@material-ui/core";
import { firestore, firebase } from "../../firebase";
import { getRevisionQuestions } from "../../util";
import "./index.scss";

const Revision = props => {
  const [revisions, setRevisions] = useState({
    inQueue: [],
    completed: [],
    one: [],
    seven: [],
    sixTeen: [],
    thirtyFive: []
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firestore
          .collection("revision")
          .doc(user.uid)
          .get()
          .then(async revisionSnap => {
            const revisionData = revisionSnap.data() || {};
            const revisionObj = getRevisionQuestions(revisionData.questions);
            setRevisions(revisionObj);
          });
      }
    });
  }, []);

  const revisionCount =
    revisions.one.length +
    revisions.seven.length +
    revisions.sixTeen.length +
    revisions.thirtyFive.length;

  return (
    <>
      
      <Paper className="dashbaord-container revision">
      <Typography variant="h6" gutterBottom color='primary'> 
        Smart Revision
      </Typography>
        <Typography color="textSecondary" gutterBottom>
          Smart revision spaces out questions with specified intervals for
          optimal memory retention. Questions with wrong answers are
          automatically saved for revision. You can also save questions
          manually. Learn more about distributed learning{" "}
          <Link
            href="https://escholarship.org/uc/item/0kp5q19x"
            target="_blank"
          >
            here
          </Link>
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={4} className="revision-grid">
            <Typography variant="h6" component="h2" gutterBottom>
              Completed
            </Typography>
            <Typography variant="h6">{revisions.completed.length}</Typography>
          </Grid>
          <Grid item xs={4} className="revision-grid">
            <Typography variant="h6" component="h2" gutterBottom>
              In Queue
            </Typography>
            <Typography variant="h6">{revisions.inQueue.length}</Typography>
          </Grid>
          <Grid item xs={4} className="revision-grid">
            <Typography variant="h6" component="h2" gutterBottom>
              Revise Now
            </Typography>
            <Typography gutterBottom variant="h6">
              {revisionCount}
            </Typography>
          </Grid>
        </Grid>
        <Button
          className="revisionButton"
          href="/revision"
          variant="contained"
          color="primary"
          disabled={revisionCount === 0}
        >
          Start Revision
        </Button>
      </Paper>
    </>
  );
};
export default Revision;
