import React, { useState, useEffect } from "react";
import axios from "axios";
import "./index.scss";
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Grid,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import Checkbox from "@material-ui/core/Checkbox";

import { firestore, firebase } from "../../firebase";
const HOST = " https://us-central1-medicalapp-ad2fc.cloudfunctions.net";

const Exams = props => {
  const { id } = props.user;
  const { availableQuestions } = props;
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(180);
  const [exams, setExams] = useState([]);
  const [questionCount, setQuestionCount] = useState(180);
  const [usedQuestions, setUsedQuestions] = useState(0);
  const [recallsOnly, setRecallsOnly] = useState(false);

  const startExam = async e => {
    e.preventDefault();
    if (questionCount < 1 || questionCount > availableQuestions - usedQuestions)
      return;
    setLoading(true);
    const token = await firebase.auth().currentUser.getIdToken();
    const examResponse = await axios.post(
      `${HOST}/createTimedExam`,
      {
        time,
        questionCount,
        recallsOnly
      },
      { headers: { authorization: "Bearer " + token } }
    );
    setLoading(false);
    window.location.href = `/exam/${examResponse.data.id}`;
  };

  const fetchExams = () => {
    let usedQs = 0;
    if (id) {
      firestore
        .collection("timedExams")
        .where("user", "==", id)
        .onSnapshot(examsSpap => {
          const tmpExams = [];
          examsSpap.forEach(examSnap => {
            const examData = examSnap.data();
            const subtitle = examData.startedAt
              ? Date.now() / 1000 - examData.startedAt.seconds >
                  examData.time * 60 || examData.ended
                ? "Ended"
                : new Date(examData.startedAt.seconds * 1000).toDateString()
              : "Not Started";

            const exam = {
              archived: examData.archived,
              createdAt: examData.createdAt.seconds,
              title: `${examData.questions.length} Questions in ${examData.time} Min`,
              subtitle: `${subtitle}${examData.archived ? " - Archived" : ""}`
            };
            if (!examData.archived) {
              usedQs = usedQs += examData.questions.length;
            }
            tmpExams.push({ ...exam, id: examSnap.id });
          });
          const sorted = tmpExams.sort((a, b) => {
            return b.createdAt - a.createdAt;
          });

          setExams(sorted);
          setUsedQuestions(usedQs);
        });
    }
  };

  const setArchiveExam = (examId, archived) => {
    setLoading(true);
    firestore
      .collection("timedExams")
      .doc(examId)
      .set({ archived }, { merge: true })
      .then(() => {
        fetchExams();
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExams();
  }, [id]);

  return (
    <>
      <Paper className="dashbaord-container dashboard-exams">
        <Typography variant="h6" gutterBottom color="primary">
          Timed Exams
        </Typography>

        {usedQuestions > 0 && (
          <Typography
            color="textSecondary"
            align="center"
            gutterBottom
            variant="subtitle1"
          >
            {" "}
            There are <strong>
              {availableQuestions - usedQuestions}
            </strong> of {availableQuestions} questions remaining to create new
            mock exams. Archive past mock exams to reuse questions from them.{" "}
          </Typography>
        )}
        <Divider />
        <br />
        <Typography variant="subtitle2">Create new mock exam</Typography>
        <form onSubmit={startExam}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <TextField
                required
                error={
                  questionCount < 1 ||
                  questionCount > availableQuestions - usedQuestions
                }
                disabled={loading}
                type="number"
                label="Question count"
                value={questionCount}
                onChange={e => {
                  setQuestionCount(e.target.value);
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                disabled={loading}
                type="number"
                label="Time in minutes"
                value={time}
                onChange={e => {
                  setTime(e.target.value);
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={3} className="recall-checkbox">
              <FormControl>
                <FormHelperText>Recalls/High-yield only</FormHelperText>
                <Checkbox
                  checked={recallsOnly}
                  onChange={e => {
                    setRecallsOnly(e.target.checked);
                  }}
                  color="primary"
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  loading ||
                  questionCount < 1 ||
                  questionCount > availableQuestions - usedQuestions
                }
                type="submit"
              >
                {loading ? <CircularProgress /> : "Start"}
              </Button>
            </Grid>
          </Grid>
        </form>
        <hr />
        <Typography variant="subtitle2">Past Exams</Typography>

        <List>
          {exams.map(exam => (
            <ListItem
              disabled={exam.archived}
              alignItems="flex-start"
              button
              key={exam.id}
              component="a"
              href={`/exam/${exam.id}`}
            >
              <ListItemText primary={exam.title} secondary={exam.subtitle} />
              <ListItemSecondaryAction>
                {!exam.archived && (
                  <IconButton
                    title="Archive Exam"
                    onClick={() => setArchiveExam(exam.id, true)}
                  >
                    <ArchiveIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
};
export default Exams;
