import "./index.scss";

import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";

import React from "react";

const tiers = [
  {
    title: "PLAB Part one",
    subHeader: "3 Months",
    price: "34",
    sku: "sku_FgRz5ujGF3Pu8x",
    term: 3,
    buttonText: "Sign up for free",
    buttonVariant: "outlined"
  },
  {
    title: "PLAB Part one",
    subHeader: "6 Months",
    price: "48",
    sku: "sku_FgRyyre0taEYfn",
    term: 6,
    buttonText: "Sign up for free",
    buttonVariant: "outlined"
  },
  {
    title: "PLAB Part one",
    subHeader: "12 Months",

    price: "74",
    sku: "sku_FbdKpEVZVNdtaf",
    term: 12,
    buttonText: "Sign up for free",
    buttonVariant: "outlined"
  },
  {
    title: "PLAB Part one",
    subHeader: "36 Months",
    price: "139",
    sku: "sku_FbdJJmr9pOltFt",
    term: 36,
    buttonText: "Sign up for free",
    buttonVariant: "outlined"
  }
];

const PricingGrid = ({ showDiscount, onPlanClicked }) => {
  return (
    <>
      <Typography
        align="center"
        variant="h6"
        color="primary"
        className="feature-text"
      >
        {
          "✔Over 3000 Questions  ✔Extended study notes and links  ✔Includes images  ✔X-Rays and ECGs  ✔Build unlimited timed mock exams"
        }
      </Typography>
      <Button
        className="sample-button"
        fullWidth
        variant="contained"
        color="secondary"
        href={`/demo/plab`}
      >
        See sample questions
      </Button>
      <br />
      <Grid container spacing={3} justify="center">
        {tiers.map((tier, t) => (
          <Grid item key={t}>
            <Paper className="price-card">
              <CardHeader
                title={tier.title}
                subheader={tier.subHeader}
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{ align: "center" }}
              />
              <CardContent>
                {showDiscount && (
                  <Typography className="discount-text">
                    <strong>10%</strong> Discount applied at checkout!
                  </Typography>
                )}
                <Typography
                  component="h3"
                  variant="h3"
                  color="textPrimary"
                  align="center"
                >
                  ${tier.price}
                </Typography>
                <Typography
                  align="center"
                  component="p"
                  variant="caption"
                  color="textSecondary"
                >
                  For all {tier.term} Months
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  className="select-plan-button"
                  variant="contained"
                  color="primary"
                  fullWidth
                  href={onPlanClicked ? null : `/auth?sku=${tier.sku}`}
                  onClick={() => {
                    onPlanClicked && onPlanClicked(tier.sku);
                  }}
                >
                  Select Plan
                </Button>
              </CardActions>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default PricingGrid;
