import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NotificationsIcon from "@material-ui/icons/Notifications";

import {
  Typography,
  LinearProgress,
  ListItemSecondaryAction,
  ListItemIcon
} from "@material-ui/core";
import { firestore } from "../../firebase";
const pluralize = require("pluralize");

const Questions = props => {
  const [expanded, setExpanded] = useState({});
  const [expandedItems, setExpandedItems] = useState({});
  const { totalQuestionCount, topics, submissions } = props;
  const topicList = Object.values(topics);

  const getExpandedItems = async topicId => {
    if (topicId) {
      const subTopicSnap = await firestore
        .collection("subTopics")
        .where("topic", "==", topicId)
        .get();
      const subTopics = [];
      subTopicSnap.forEach(subTopic => {
        subTopics.push({ id: subTopic.id, ...subTopic.data() });
      });
      setExpandedItems({ ...expandedItems, [topicId]: subTopics });
      setExpanded({
        ...expanded,
        [topicId]: !expanded[topicId]
      });
    } else {
      return [];
    }
  };

  return (
    <>
      <Paper className="dashbaord-container dashboard-questionlist">
        <Typography variant="h6" gutterBottom color="primary">
          Questions - {totalQuestionCount}
        </Typography>

        <div className="beta-message">
          <NotificationsIcon />
          <Typography variant="caption" gutterBottom>
            Recalls/High yield questions is a beta feature. We will be adding
            new and improved questions daily.
          </Typography>
        </div>
        {topicList.length === 0 ? (
          <>
            <Typography
              variant="body1"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Checking back your progress...
            </Typography>
            <LinearProgress variant="query" />
          </>
        ) : (
          <List>
            {topicList.map((topic, t) => (
              <span key={t}>
                <ListItem
                  button
                  key={topic.id}
                  href={topic.expand ? undefined : `/questions/${topic.id}`}
                  onClick={
                    topic.expand
                      ? () => {
                          getExpandedItems(topic.id);
                        }
                      : () => {}
                  }
                  component="a"
                >
                  <ListItemText
                    primary={topic.name}
                    secondary={
                      <React.Fragment>
                        <Typography
                          className="question-list-secondary"
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {`Attempted ${
                            submissions[topic.id]
                              ? Object.keys(submissions[topic.id]).length - 1
                              : 0
                          } of ${Object.keys(topic.questions || {}).length}`}
                        </Typography>
                        {` - `}
                        {Object.keys(topic.pastQuestions || {}).length > 0 && (
                          <Typography
                            className="question-list-recall"
                            component="span"
                            variant="subtitle2"
                            color="textPrimary"
                          >
                            {pluralize(
                              "High-yield / Recalls questions",
                              Object.keys(topic.pastQuestions || {}).length,
                              true
                            )}
                          </Typography>
                        )}
                        <br />
                        {topic.lastAttempted &&
                          `Last Attempted: ${topic.lastAttempted}`}
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    {topic.expand ? (
                      expanded[topic.id] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={expanded[topic.id]} timeout="auto" unmountOnExit>
                  {expanded[topic.id] && (
                    <List component="div" disablePadding>
                      {expandedItems[topic.id].map((item, p) => (
                        <ListItem
                          key={p}
                          button
                          component={"a"}
                          href={`/questions/${topic.id}/${item.id}`}
                        >
                          <ListItemIcon>
                            <RemoveIcon />
                          </ListItemIcon>
                          <ListItemText primary={item.name} />
                          <ListItemSecondaryAction>
                            <KeyboardArrowRightIcon />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Collapse>
              </span>
            ))}
          </List>
        )}
      </Paper>
    </>
  );
};
export default Questions;
