import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import Header from "./components/Header";
import Questions from "./pages/Questions";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";

export default function App() {
  return (
    <>
      <Header />
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/terms" exact component={Terms} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/auth" exact component={Auth} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route
          path="/questions/:topicOrExamId?/:subTopicID?"
          exact
          component={Questions}
        />
        <Route path="/exam/:topicOrExamId" exact component={Questions} />
        <Route path="/revision" exact component={Questions} />
        <Route path="/bookmarks" exact component={Questions} />
        <Route path="/demo/:type" exact component={Questions} />
      </Router>
    </>
  );
}
